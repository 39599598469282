<template>
  <div
    v-if="invoiceData"
    class="advanced-invoice"
  >
    <b-card
      :title="`Fatura Nº ${pad(invoiceData.invoice_number, 6)}`"
      class="mb-1 card-info"
    >
      <b-card-body>
        <div class="d-flex flex-column flex-md-row">
          <table class="align-self-baseline">
            <tr>
              <th class="pb-50">
                <span class="font-weight-bold pr-1">Instituição</span>
              </th>
              <td class="pb-50">
                {{ invoiceData.institution.name }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <span class="font-weight-bold">Período</span>
              </th>
              <td class="pb-50 text-nowrap">
                {{ formatDateTimeDDMMYYYY(invoiceData.start_date) }} -
                {{ formatDateTimeDDMMYYYY(invoiceData.end_date) }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <span class="font-weight-bold pr-1">Status</span>
              </th>
              <td class="pb-50">
                <b-badge
                  v-if="invoiceData.status === 'paid'"
                  pill
                  variant="light-success"
                >
                  Pago
                </b-badge>
                <b-badge
                  v-if="invoiceData.status === 'open'"
                  pill
                  variant="light-primary"
                >
                  Aberto
                </b-badge>
                <b-badge
                  v-if="invoiceData.status === 'expired'"
                  pill
                  variant="light-danger"
                >
                  Vencido
                </b-badge>
              </td>
            </tr>
          </table>
          <table class="align-self-baseline ml-2">
            <tr>
              <th class="pb-50">
                <span class="font-weight-bold">Vencimento</span>
              </th>
              <td class="pb-50">
                {{ formatDateTimeDDMMYYYY(invoiceData.deadline) }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <span class="font-weight-bold">Pagamento</span>
              </th>
              <td class="pb-50">
                <span style="min-width: 20px;display: inline-block;text-align: center">{{ invoiceData.paid_at ? formatDateTimeDDMMYYYY(invoiceData.paid_at) : '-' }}</span>
                |
                {{ formatCurrencyInt(invoiceData.amount_paid) }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <span class="font-weight-bold">Outros Acrésc.</span>
              </th>
              <td class="pb-50">
                {{ formatCurrencyInt(invoiceData.additions || 0) }}
              </td>
            </tr>
          </table>
        </div>
        <div>
          <span class="font-weight-bold">Nota</span>
          <div>
            {{ invoiceData.note || '-' }}
          </div>
        </div>
        <div class="float-right-top">
          <b-button
            v-b-modal.modal-invoice
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            class="btn-icon mx-1"
            size="sm"
          >
            Reaberturas
            <b-badge
              pill
              variant="light-secondary"
            >
              {{ invoiceData.reopen_history.length }}
            </b-badge>
          </b-button>
        </div>
      </b-card-body>
    </b-card>

    <b-card
      class="mb-0"
      title="Antecipações"
    >
      <div class="text-right titles">
        <div class="d-flex justify-content-between">
          <div class="d-flex justify-content-start">
            <div
              v-for="month in months"
              :key="month.date"
              class="d-flex justify-content-start mr-1"
            >
              <div class="d-flex flex-column text-left">
                <span class="text-muted total">{{ formatDateTimeMMMMYYYY(month.date) }}</span>
                <span class="amount align-self-end ml-0"> {{ formatCurrencyInt(month.total) }}</span>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <div class="d-flex flex-column text-left">
              <span class="text-muted total">Total</span>
              <span class="text-muted text-">Instituição</span>
            </div>
            <span class="amount align-self-end"> {{ formatCurrencyInt(invoiceData.total) }}</span>
          </div>
        </div>
      </div>
      <app-collapse
        accordion
        type="border"
        class="mb-1"
      >
        <app-collapse-item
          v-for="workplace in invoiceData.workplaces"
          :key="workplace.workplace_id"
          :title="workplace.workplace_name"
        >
          <template v-slot:header>
            <div
              class="d-flex justify-content-between w-100"
              :class="{'text-removed': false}"
            >
              <div class="d-flex">
                <b-badge
                  pill
                  variant="light-primary"
                  title="Quantidade de plantões"
                >
                  {{ workplace.items.length }}
                </b-badge>
                <div class="ml-1">
                  <h6>Unidade</h6>
                  <h4>{{ workplace.workplace_name }}</h4>
                </div>
              </div>
              <div class="text-right">
                <div>
                  <span class="text-muted">Total</span>
                  <span class="collapse-title"> {{ formatCurrencyInt(workplace.total) }}</span>
                </div>
              </div>
            </div>
          </template>
          <b-table
            ref="refInstitutionListTable"
            :items="() => workplace.items"
            responsive
            :fields="tableColumns"
            primary-key="id"
            show-empty
            empty-text="Nenhum registro correspondente encontrado"
            class="position-relative"
          >
            <template #cell(company)="{ item }">
              <b-badge
                pill
                :variant="item.person_type === 'PJ' ? 'light-danger' : 'light-success'"
              >
                {{ item.person_type || 'PF' }}
              </b-badge>
              <div v-if="item.physician_company_cnpj">
                <strong>{{ item.physician_company_name }}</strong>
                <div>{{ formatCnpj(item.physician_company_cnpj) }}</div>
              </div>
              <div v-else>
                -
              </div>
            </template>
            <template
              #cell(anteciped_at)="data"
            >
              {{ formatDate(new Date(data.item.anteciped_at), 'dd/MM/yy HH:mm') }}
            </template>
            <template #cell(start_date)="data">
              {{ formatDate(new Date(data.item.start_date), 'dd/MM/yy HH:mm') }} -
              {{ formatDate(new Date(data.item.end_date), 'dd/MM/yy HH:mm') }}
            </template>
            <template #cell(amount_paid)="{ item }">
              <div>
                {{ formatCurrency(item.amount_paid) }}
              </div>
              <div>
                <b-badge
                  pill
                  class=""
                  :variant="item.source === 'physician_duty' ? 'light-success' : 'light-danger'"
                >
                  {{ item.source === 'physician_duty' ? 'Plantão' : 'Serviço' }}
                </b-badge>
              </div>
            </template>
          </b-table>
        </app-collapse-item>
      </app-collapse>
    </b-card>

    <b-modal
      id="modal-invoice"
      title="Histórico de Reabertura"
      hide-footer
      size="lg"
      centered
    >
      <b-table
        ref="refReopenListTable"
        :items="() => invoiceData.reopen_history"
        responsive
        :fields="tableReopenColumns"
        primary-key="id"
        show-empty
        empty-text="Nenhum registro correspondente encontrado"
        class="position-relative"
      >
        <template
          #cell(reopen_at)="data"
        >
          {{ formatDateTime(new Date(data.item.reopen_at), 'dd/MM/yy HH:mm') }}
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BBadge, BButton, BCard, BCardBody, BModal, BTable,
} from 'bootstrap-vue'
import { resolveStatusVariant } from '@/views/financial/payment-remittance-list/usePaymentRemittanceList'
import {
  formatDateTime, maskCnpj, maskHiddenCpf, useRouter,
  formatCurrency, formatCurrencyInt, formatDate, formatDateTimeDDMMYYYY, pad, formatDateTimeMMMMYYYY, formatCnpj,
} from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import userStoreModule from './invoiceStoreModule'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    BButton,
    BModal,
    BBadge,
    BCardBody,
    BCard,
    BTable,
  },
  directives: {
    Ripple,
  },
  methods: { formatCnpj, pad },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    const invoiceData = ref(null)
    const months = ref([])
    const tableColumns = [
      {
        key: 'physician_name',
        label: 'Médico',
      },
      {
        key: 'physician_cpf',
        label: 'CPF',
      },
      {
        key: 'company',
        label: 'Empresa',
      },
      {
        key: 'anteciped_at',
        label: 'Antecipado',
      },
      {
        key: 'start_date',
        label: 'Data',
      },
      {
        key: 'amount_paid',
        label: 'Valor',
      },
    ]
    const tableReopenColumns = [
      {
        key: 'reopen_at',
        label: 'Data',
      },
      {
        key: 'reopen_reason',
        label: 'Motivo',
      },
      {
        key: 'reopen_user',
        label: 'Usuário',
      },
    ]

    store.dispatch('app-invoice/fetchInvoice', { id: router.currentRoute.params.id })
      .then(response => {
        invoiceData.value = response.data.data
        // eslint-disable-next-line guard-for-in,no-restricted-syntax
        for (const month in invoiceData.value.total_per_month_advance) {
          months.value.push({
            date: month,
            total: invoiceData.value.total_per_month_advance[month],
          })
        }
      })
      .catch(error => {
        if (error.response.status === 404) {
          invoiceData.value = undefined
        }
      })

    return {
      months,
      formatDateTimeMMMMYYYY,
      tableColumns,
      tableReopenColumns,
      invoiceData,
      resolveStatusVariant,
      formatDateTime,
      maskCnpj,
      maskHiddenCpf,
      useRouter,
      formatDate,
      formatDateTimeDDMMYYYY,
      formatCurrency,
      formatCurrencyInt,
    }
  },
}
</script>

<style lang="scss" >
.card-title {
  margin-bottom: 5px!important;
}
.card-info .card-body > .card-body {
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 0;

  th, td {
    padding: 0!important;
  }
  table td {
    padding-left: 4px!important;
  }

  .btn {
    margin-left: 0!important;
    margin-top: 0.5rem;
  }
}

.float-right-top {
  position: absolute;
  top: 5px;
  right: 5px;
}

.advanced-invoice {
  .panel-physician {
    background-color: rgb(248, 248, 248);
  }

  label {
    margin-top: 0.2857rem!important;
  }

  .collapse-border .card .card-header {
    padding-top: .5rem;
    padding-bottom: .5rem;
    h4 {
      margin-bottom: 0;
    }
    div.d-flex {
      align-items: center;
    }
  }

  .duty-dates {
    align-items: center;
    h1 {
      color: #898993;
      font-size: 14px;
      margin-bottom: 0;
      margin-right: 10px;
    }
  }

  .physician-label {
    font-size: 12px;
  }

  .btn-remove {
    padding: 8px 14px;
  }
  .text-removed {
    text-decoration: line-through;
    color: #9fa2a7;
    h4, h6 {
      color: #9fa2a7;
    }
  }
  .btn-success {
    padding: 0.615rem 0.636rem!important;
  }
  h6 {
    color: #6e6b7b;
    font-size: 0.85rem;
    font-weight: 400;
    margin-bottom: 0;
  }

  .btn-unit {
    margin-bottom: 0.5rem!important;
  }

  .titles {
    font-weight: bold;
    .text-muted {
      color: #575756!important;
    }
    .total {
      font-weight: 400;
      font-size: 0.75rem;
    }
    .amount {
      margin-left: 3px;
    }
  }
}
</style>
